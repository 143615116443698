.card {
  min-height: 400px;
}

.image-container {
  height: 200px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.component-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 1rem;
}

.card-title {
  height: 1.5rem;
  overflow: hidden;
}

.card-body {
  padding: 1rem;
}

.multiline-text {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 4.5rem;  /* Approximately 3 lines of text */
    margin-bottom: 0.5rem;
}

.card .btn-outline-secondary {
  margin-bottom: 0.5rem;
}

.card .btn-outline-secondary:last-child {
  margin-bottom: 0;
}

/* Optional: Add hover effect to highlight best price */
/* .card .btn-outline-secondary:first-child {
  border-color: #28a745;
  color: #28a745;
}

.card .btn-outline-secondary:first-child:hover {
  background-color: #28a745;
  color: white;
} */